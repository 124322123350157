"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class ImageryControl {
    constructor() {
        // this.sources = sources || MarksImageryControl.DEFAULT_SOURCES;
        this.onDocumentClick = this.onDocumentClick.bind(this);
        this.events = undefined;
        this.sources = [
          { layer_id: '2020-leaf-off',
            source_id: '2020-leaf-off-src',
            type: 'raster',
            title: '2020 (Natural)',
            tiles: [
              'https://img.nj.gov/imagerywms/Natural2020?bbox=' +
              '{bbox-epsg-3857}&format=image/png&service=WMS&version=' +
              '1.1.1&request=GetMap&srs=EPSG:3857&transparent=true&width=' +
              '256&height=256&layers=Natural2020'
            ],
            tileSize: 256,
            attribution:
                'Background: <a href="https://newjersey.maps.arcgis.com/home/item.html?id=b86657d214234c989f3383316d3dc695" target="_blank">NJOIT 2020 Natural</a>'
      
          },
          { layer_id: '2015-leaf-off',
            source_id: '2015-leaf-off-src',
            type: 'raster',
            title: '2015 (Natural)',
            tiles: [
              'https://img.nj.gov/imagerywms/Natural2015?bbox=' +
              '{bbox-epsg-3857}&format=image/png&service=WMS&version=' +
              '1.1.1&request=GetMap&srs=EPSG:3857&transparent=true&width=' +
              '256&height=256&layers=Natural2015'
            ],
            tileSize: 256,
            attribution:
                'Background: <a href="https://newjersey.maps.arcgis.com/home/item.html?id=3c1090054fee4a498514659d57bfe4ae" target="_blank">NJOIT 2015 Natural</a>'
      
          },
          { layer_id: '2012-leaf-off',
            source_id: '2012-leaf-off-src',
            type: 'raster',
            title: '2012 (Natural)',
            tiles: [
              'https://img.nj.gov/imagerywms/Natural2012?bbox=' +
              '{bbox-epsg-3857}&format=image/png&service=WMS&version=' +
              '1.1.1&request=GetMap&srs=EPSG:3857&transparent=true&width=' +
              '256&height=256&layers=Natural2012'
            ],
            tileSize: 256,
            attribution:
                'Background: <a href="https://newjersey.maps.arcgis.com/home/item.html?id=153e2820ad454ffcbfddd9fda4ec3d7e" target="_blank">NJOIT 2012 Natural</a>'
      
          },
          { layer_id: '2007-leaf-off',
            source_id: '2007-leaf-off-src',
            type: 'raster',
            title: '2007 (Natural)',
            tiles: [
              'https://img.nj.gov/imagerywms/Natural2007?bbox=' +
              '{bbox-epsg-3857}&format=image/png&service=WMS&version=' +
              '1.1.1&request=GetMap&srs=EPSG:3857&transparent=true&width=' +
              '256&height=256&layers=Natural2007'
            ],
            tileSize: 256,
            attribution:
                'Background: <a href="https://newjersey.maps.arcgis.com/home/item.html?id=1149a594baa045f2ada44b8291c24aa1" target="_blank">NJOIT 2007 Natural</a>'
      
          },
          { layer_id: '2002-leaf-off',
            source_id: '2002-leaf-off-src',
            type: 'raster',
            title: '2002 (Infrared)',
            tiles: [
              'https://img.nj.gov/imagerywms/Infrared2002?bbox=' +
              '{bbox-epsg-3857}&format=image/png&service=WMS&version=' +
              '1.1.1&request=GetMap&srs=EPSG:3857&transparent=true&width=' +
              '256&height=256&layers=Infrared2002'
            ],
            tileSize: 256,
            attribution:
                'Background: <a href="https://newjersey.maps.arcgis.com/home/item.html?id=fb3b62a283504c74ab422c14862f9cf7" target="_blank">NJOIT 2002 Infrared</a>'
          },
          { layer_id: '1995-leaf-off',
            source_id: '1995-leaf-off-src',
            type: 'raster',
            title: '1995 (Infrared)',
            tiles: [
              'https://img.nj.gov/imagerywms/Infrared1995?bbox=' +
              '{bbox-epsg-3857}&format=image/png&service=WMS&version=' +
              '1.1.1&request=GetMap&srs=EPSG:3857&transparent=true&width=' +
              '256&height=256&layers=Infrared1995'
            ],
            tileSize: 256,
            attribution:
                'Background: <a href="https://newjersey.maps.arcgis.com/home/item.html?id=29b6ab4cc9384713abcbb66875d2d4de" target="_blank">NJOIT 1995 Infrared</a>'
      
          },
          { layer_id: '1980s-leaf-off',
            source_id: '1980s-leaf-off-src',
            type: 'raster',
            title: '1980s (Infrared)',
            tiles: [
              'https://img.nj.gov/imagerywms/Infrared1980-1987?bbox=' +
              '{bbox-epsg-3857}&format=image/png&service=WMS&version=' +
              '1.1.1&request=GetMap&srs=EPSG:3857&transparent=true&width=' +
              '256&height=256&layers=Infrared1980-1987'
            ],
            tileSize: 256,
            attribution:
                'Background: <a href="https://newjersey.maps.arcgis.com/home/item.html?id=10714254a0b9489ca18a0354ad6c028" target="_blank">NJOIT 1980-1987 Infrared</a>'
      
          }
        ];
    }
    getDefaultPosition() {
        const defaultPosition = "top-right";
        return defaultPosition;
    }
    onAdd(map) {
        this.map = map;
        this.controlContainer = document.createElement("div");
        this.controlContainer.classList.add("maplibregl-ctrl");
        this.controlContainer.classList.add("maplibregl-ctrl-group");
        this.controlContainer.classList.add("grid");
        this.controlContainer.classList.add("grid");
        this.controlContainer.classList.add("grid-cols-1");
        this.controlContainer.title = 'Add Ortho Image';
        this.mapSourceContainer = document.createElement("div");
        this.mapSourceContainer.classList.add("maplibregl-style-list");
        // this.mapSourceContainer.classList.add("hiddden");
        this.mapSourceContainer.style.display = 'none';
        this.mapSourceContainer.style.padding = '.6em';
        this.sourceButton = document.createElement("button");
        this.sourceButton.type = "button";

        for (const source of this.sources) {
            const layerDiv = document.createElement("div"); 
            layerDiv.classList.add('col-span-1');
            const layerInput = document.createElement("INPUT");
            layerInput.setAttribute("type", "checkbox");
            layerInput.id = source.layer_id;
            layerInput.classList.add('image-input');
            layerInput.style.verticalAlign = 'text-bottom';
            layerInput.dataset.type = source.type;
            layerInput.dataset.tiles = JSON.stringify(source.tiles);
            layerInput.dataset.layer_id = source.layer_id;
            layerInput.dataset.source_id = source.source_id;
            layerInput.dataset.attribution = source.attribution;
            layerInput.addEventListener("click", event => {
                const srcElement = event.srcElement;
                if(!srcElement.checked){
                  this.map.removeLayer(source.layer_id);
                  return
                }
                // this.closeModal();
                if (srcElement.classList.contains("active")) {
                    return;
                }
                if (this.events && this.events.onOpen && 
                    this.events.onOpen(event)) {
                    return;
                }
                const inputs = document.getElementsByClassName('image-input');
                for(const input of inputs){
                  if(input.id != source.layer_id){
                    input.checked = false;
                  }
                }
                const tiles = JSON.parse(srcElement.dataset.tiles);
                const type = srcElement.dataset.type;
                const layer_id = srcElement.dataset.layer_id;
                const source_id = srcElement.dataset.source_id;
                const attribution = srcElement.dataset.attribution;

                for(const source of this.sources){
                  if(this.map.getLayer(source.layer_id)){
                    this.map.removeLayer(source.layer_id);
                  }
                }

                if(!this.map.getSource(source_id)){
                  this.map.addSource(source_id, {
                    'type': type,
                    'tiles': tiles,
                    'tileSize': 256,
                    'attribution': attribution
                  });
                }
                const reference_layer = 
                          this.map.getLayer('range_lines')?'range_lines': null;

                this.map.addLayer({
                  'id': layer_id,
                  'type': type,
                  'source': source_id,
                  'paint': {}
                }, reference_layer);

                const elms = this.mapSourceContainer
                                 .getElementsByClassName("active");
                while (elms[0]) {
                    elms[0].classList.remove("active");
                }
                srcElement.classList.add("active");
                if (this.events && this.events.onChange && 
                    this.events.onChange(event, source)) {
                    return;
                }
            });
            const layerLabel = document.createElement("label");
            layerLabel.htmlFor = source.layer_id;
            layerLabel.innerHTML=source.title;
            layerLabel.classList.add('inline');
            layerLabel.classList.add('!text-red-500');
            layerLabel.style.padding = '0em .5em 0em .5em';
            layerDiv.appendChild(layerInput);
            layerDiv.appendChild(layerLabel)
            this.mapSourceContainer.appendChild(layerDiv);
        }
        this.sourceButton.classList.add("maplibregl-ctrl-icon");
        this.sourceButton.classList.add("marks-image-control");
        this.sourceButton.addEventListener("click", event => {
            if (this.events && this.events.onSelect && 
                this.events.onSelect(event)) {
                return;
            }
            this.openModal();
        });
        document.addEventListener("click", this.onDocumentClick);
        this.controlContainer.appendChild(this.sourceButton);
        this.controlContainer.appendChild(this.mapSourceContainer);
        return this.controlContainer;
    }
    onRemove() {
        if (!this.controlContainer || !this.controlContainer.parentNode || 
            !this.map || !this.sourceButton) {
            return;
        }
        this.sourceButton.removeEventListener("click", this.onDocumentClick);
        this.controlContainer.parentNode.removeChild(this.controlContainer);
        document.removeEventListener("click", this.onDocumentClick);
        this.map = undefined;
    }
    closeModal() {
        if (this.mapSourceContainer && this.sourceButton) {
            this.mapSourceContainer.style.display = "none";
            this.sourceButton.style.display = "block";
        }
    }
    openModal() {
        if (this.mapSourceContainer && this.sourceButton) {
            this.mapSourceContainer.style.display = "block";
            this.sourceButton.style.display = "none";
        }
    }
    onDocumentClick(event) {
        if (this.controlContainer && 
            !this.controlContainer.contains(event.target)) {
          this.closeModal();
        }
    }
}
exports.ImageryControl = ImageryControl;