<template>

  <div class="relative mx-auto top-0 w-full md:max-w-[1920px]">
    <div id="content-container" class="fixed h-full w-full md:max-w-[1920px]">
      <div id="content-wrapper" 
           class="flex h-full flex-col md:flex-row basis-1 grow bg-white 
                  border-t border-gray-300">
        <content-component
          v-bind:map = "map"
          v-bind:map_loaded = "map_loaded"
          v-bind:popup = "popup"
          v-bind:selected_overlay = "selected_overlay"
          v-bind:selected_biopid = "selected_biopid"
          @basemap_change="updateBasemapChange"
          @map="updateMap"
          @map_loaded="updateMapLoaded"
          @popup="updatePopup"
          @server_error="updateServerError"/>
      </div>
    </div>
  </div>
  
</template>

<script>

import ContentComponent from "./ContentComponent.vue" 
import landscape_logo_svg from "../assets/img/landscape_logo.svg" 

  export default {
    name: 'HomeComponent',

    components:{
      "content-component": ContentComponent,
      // "menu-component": MenuComponent,
    },

    data() {
      return {
        basemap_change: false,
        landscape_logo_svg,
        map: null,
        map_loaded: false,
        popup: null,
        selected_overlay: null,
        selected_biopid: null,
        server_error: null
      }
    },

    methods:{
      changeOverlay(overlay){
        this.selected_overlay = overlay;
      },
      
      changeSelection(selected_biopid){
        this.selected_biopid = selected_biopid;
      },

      updateBasemapChange(){
        this.basemap_change = !this.basemap_change;
      },

      updateMap(map){
        this.map = map;
      },

      updateMapLoaded(status){
        this.map_loaded = status;
      },

      updatePopup(popup){
        this.popup = popup;
      },

      updateServerError(err){
        this.server_error = err;
      }
    }
  }

</script>