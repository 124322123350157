<template>
<div class="border-2 border-neutral-100 hover:shadow--xl h-full
              bg-neutral-50 overflow-y-auto"
        v-show="show">
      
  <header-component/>
 <div class="text-xs md:text-sm lg:text-sm xl:text-base 2xl:text-xl 
             align-top items-center"
        v-show="!full_screen">
      <div class="grid grid-cols-6 ml-5 mt-6 ">
        <div class="pb-2 col-span-5 col-start-1">
          <label for="biopid" 
                 class="pl-2 font-bold text-hcap_green"
          >Species (biopid)</label>
        </div>
        <div class="col-span-5 col-start-1 ml-5 z-50">
          <select class="w-full border border-neutral-200 
                         text-neutral-800 rounded shadow z-50"
                  id="biopid"
                  v-model="selected_biopid">
            <option class=" z-50" value="" selected>Select</option>
            <option class=" z-50"
                    v-for="option in biopid_list.data" 
                    v-bind:value="option.biopid"
                    v-bind:key="option.biopid"
                    >{{option.description }} ({{option.biopid }})</option>
          </select>
        </div>
      </div>
      <div>
        <version-menu
          v-bind:selected_biopid = "selected_biopid"
          @selected_versions="updatesSelected_versions"/>
        <lulc-component
          v-bind:opacity = "land_use_opacity"
          v-bind:map = "map"
          @land_use="emitLandUse"/>
        <select-component
          v-bind:selected_biopid="selected_biopid"
          @state="emitState"/>          

      </div>


  </div>
</div>

</template>

<script>

  import bounds_list from '../assets/data/bounds.json'
  import biopid_list from '../assets/data/biopids.json'
  import HeaderComponent from "./HeaderComponent.vue" 
  import LandUseComponent from "./LandUseComponent";
  import maplibregl from 'maplibre-gl';
  import SelectComponent from "./SelectComponent";
  import VersionMenu from "./VersionMenu";

  export default {

    name: "MenuComponent",

components:{
  "header-component": HeaderComponent,
  'lulc-component': LandUseComponent,
  'select-component': SelectComponent,
  'version-menu': VersionMenu
},

    props:[
      'basemap_change',
      'map',
      'map_loaded',
      'feature_opacity',
      'land_use_opacity',
      'popup',
      'selected_overlay',
      'server_error',
      'visible'
    ],

    emits:[
      'land_use',
      'selected_biopid',
      'selected_versions',
      'state'
    ],

    data(){
      return{
          biopid_list,
          bounds_list,
          focusedPatch: null,
          full_screen: null,
          land_use: false,
          layers: {
            patches_86: {
              'id': 'patches-86',
              'type': 'fill',
              'source': 'patches-86',
              'source-layer': 'hcap.patches',
            },
            patches_95: {
              'id': 'patches-95',
              'type': 'fill',
              'source': 'patches-95',
              'source-layer': 'hcap.patches',
            },
            patches_02: {
              'id': 'patches-02',
              'type': 'fill',
              'source': 'patches-02',
              'source-layer': 'hcap.patches',
            },
            patches_07: {
              'id': 'patches-07',
              'type': 'fill',
              'source': 'patches-07',
              'source-layer': 'hcap.patches',
            },
            patches_12: {
              'id': 'patches-12',
              'type': 'fill',
              'source': 'patches-12',
              'source-layer': 'hcap.patches',
            },
            patches_15: {
              'id': 'patches-15',
              'type': 'fill',
              'source': 'patches-15',
              'source-layer': 'hcap.patches',
            },
            patches_20: {
              'id': 'patches-20',
              'type': 'fill',
              'source': 'patches-20',
              'source-layer': 'hcap.patches',
            },
            range: {
              'id': 'ranges',
              'type': 'fill',
              'source': 'ranges',
              'source-layer': 'hcap.range_extent',

            },
            range_lines: {
              'id': 'range_lines',
              'type': 'line',
              'source': 'range_lines',
              'source-layer': 'hcap.range_extent_lines',

            }
          },
          
          paint: {
            'fill': {
                'fill-color': 'rgba(229, 57, 53, .5)',
                'fill-outline-color': 'rgba(229, 57, 53, .9)',
                // 'fill-opacity-transition': { duration: 500 },
                'line-color': 'rgba(229, 57, 53, .9)',
                'line-width': 1

              },
            'outline_only': {
                'fill-color': 'transparent',
                'fill-outline-color': 'transparent',
                'line-color': 'rgba(229, 57, 53, .9)',
                'line-width': 5
              },
            'patches-86': {
                'fill-color': 'rgba(242, 47, 0, .5)',
                'fill-outline-color': 'rgba(0,0,0,1)',
                // 'fill-opacity-transition': { duration: 500 }
              },
            'patches-95': {
                'fill-color': 'rgba(38, 255, 255, .5)',
                'fill-outline-color': 'rgba(0,0,0,1)',
                // 'fill-opacity-transition': { duration: 500 }
              },
            'patches-02': {
                'fill-color': 'rgba(217, 152, 0, .5)',
                'fill-outline-color': 'rgba(0,0,0,1)',
                // 'fill-opacity-transition': { duration: 500 }
              },
            'patches-07': {
                'fill-color': 'rgba(255, 221, 0, .5)',
                'fill-outline-color': 'rgba(0,0,0,1)',
                // 'fill-opacity-transition': { duration: 500 }
              },
            'patches-12': {
                'fill-color': 'rgba(255, 77, 196, .5)',
                'fill-outline-color': 'rgba(0,0,0,1)',
                // 'fill-opacity-transition': { duration: 500 }
              },
            'patches-15': {
                'fill-color': 'rgba(95, 245, 0, .5)',
                'fill-outline-color': 'rgba(0,0,0,1)',
                // 'fill-opacity-transition': { duration: 500 }
              },
            'patches-20': {
                'fill-color': 'rgba(0, 64, 255, .5)',
                'fill-outline-color': 'rgba(0,0,0,1)',
                // 'fill-opacity-transition': { duration: 500 }
              },
          },
          selected_biopid: null,
          selected_versions: null,
          show: true,
          sources: {
            patches_86: {
              'name': 'patches-86',
              'type': 'vector',
              'tiles': ['https://d2z3gst2m1gd4d.cloudfront.net/' + 
                        'patches/' + this.selected_biopid + '/1986' + 
                        '/{z}/{x}/{y}.pbf'],
              'minzoom': 4,
              'maxzoom': 14,
            },
            patches_95: {
              'name': 'patches-95',
              'type': 'vector',
              'tiles': ['https://d2z3gst2m1gd4d.cloudfront.net/' + 
                        'patches/' + this.selected_biopid + '/1995' + 
                        '/{z}/{x}/{y}.pbf'],
              'minzoom': 4,
              'maxzoom': 14,
            },
            patches_02: {
              'name': 'patches-02',
              'type': 'vector',
              'tiles': ['https://d2z3gst2m1gd4d.cloudfront.net/' + 
                        'patches/' + this.selected_biopid + '/2002' + 
                        '/{z}/{x}/{y}.pbf'],
              'minzoom': 4,
              'maxzoom': 14,
            },
            patches_07: {
              'name': 'patches-07',
              'type': 'vector',
              'tiles': ['https://d2z3gst2m1gd4d.cloudfront.net/' + 
                        'patches/' + this.selected_biopid + '/2007' + 
                        '/{z}/{x}/{y}.pbf'],
              'minzoom': 4,
              'maxzoom': 14,
            },
            patches_12: {
              'name': 'patches-12',
              'type': 'vector',
              'tiles': ['https://d2z3gst2m1gd4d.cloudfront.net/' + 
                        'patches/' + this.selected_biopid + '/2012' + 
                        '/{z}/{x}/{y}.pbf'],
              'minzoom': 4,
              'maxzoom': 14,
            },
            patches_15: {
              'name': 'patches-15',
              'type': 'vector',
              'tiles': ['https://d2z3gst2m1gd4d.cloudfront.net/' + 
                        'patches/' + this.selected_biopid + '/2015' + 
                        '/{z}/{x}/{y}.pbf'],
              'minzoom': 4,
              'maxzoom': 14,
            },
            patches_20: {
              'name': 'patches-20',
              'type': 'vector',
              'tiles': ['https://d2z3gst2m1gd4d.cloudfront.net/' + 
                        'patches/' + this.selected_biopid + '/2020' + 
                        '/{z}/{x}/{y}.pbf'],
              'minzoom': 4,
              'maxzoom': 14,
            },
            range: {
              'name': 'ranges',
              'type': 'vector',
              'tiles': ['https://d2z3gst2m1gd4d.cloudfront.net/' + 
                                'range_extents/{z}/{x}/{y}.pbf'],
              'minzoom': 4,
              'maxzoom': 14,
            },
            range_lines: {
              'name': 'range_lines',
              'type': 'vector',
              'tiles': ['https://d2z3gst2m1gd4d.cloudfront.net/' + 
                                'range_extents_lines/{z}/{x}/{y}.pbf'],
              'minzoom': 4,
              'maxzoom': 14,
            }
          },
          tile_root: 'https://d2z3gst2m1gd4d.cloudfront.net/' 
        }
    }, 

    watch:{
      basemap_change: function(){
        this.restoreLayers();
      },

      feature_opacity: function(){
        this.adjustFeautureOpacity();
      },

      selected_biopid: function(){
        this.emitSelection();
        if(this.selected_biopid){
          this.addSources();
          this.updateRangeLayers();
          this.updatePatchTileSources();
          // this.updatePatchLayers();
          this.repaintMap();
          const b_obj = this.bounds_list.find(item => 
          item.biopid === this.selected_biopid);
          const bounds = new maplibregl.LngLatBounds(b_obj.bounds);
          this.map.fitBounds(bounds);
        }else{
          this.removeLayers();
        }
      },

      selected_versions: {
        deep: true,
        handler() {
          this.$emit('selected_versions', this.selected_versions);
          this.updatePatchLayers();
          this.repaintMap();
        }
      },
      server_error: function(){
        this.removeLayers();
      },

      visible: {
        deep: true,
        handler(){
          if(this.visible[this.$.type.name]){
            this.show = true;
          }else{
            this.show = false;
          }
        }
      },
    },

    methods:{

      addInteractions(layer_id){
        // this.map.on('mouseleave', layer_id, () => this.popup.remove());
        this.map.on('click', layer_id, (e) => this.addClickHandler(e));
      },

      addClickHandler(e){
        if (e.features.length == 0) { return; } 
        if(this.focusedPatch){
          if(e.features[0].properties.patch_id == this.focusedPatch[1]){
            return;
          }
        }

        const coordinates = e.lngLat;
        const html = this.getPopupContent(e);

        this.map.getCanvas().style.cursor = 'pointer';        
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        this.popup.setLngLat(coordinates).setHTML(html).addTo(this.map);
        if (e.features[0].properties.patch_id) {
            this.highlightPatch(e);
        }
        this.popup.on('close', () => {
          if(this.focusedPatch){
            this.removePatchHighlight();
          }
        });
      },

      addSources(){

        for (const source of Object.values(this.sources)) {
          if(!this.map.getSource(source.name)){
              this.map.addSource(source.name, source);
          }
        }       
      },

      adjustFeautureOpacity(){
        for(const value of Object.values(this.layers)){
          if(this.map.getLayer(value.id)){
            if(value.type == 'fill'){
              this.map.setPaintProperty(value.id, 'fill-opacity', 
                                      this.feature_opacity);
            }else if(value.type == 'line'){
              this.map.setPaintProperty(value.id, 'line-opacity', 
                                      this.feature_opacity);
            }
          }
        }
      },

      emitLandUse(land_use){
        this.$emit("land_use", land_use);
      },

      emitSelection(){
        this.$emit("selected_biopid", this.selected_biopid);
      },

      emitState(value){
        this.$emit('state', value);
      },

      getLabelLayer(){
        const layers = this.map.getStyle().layers;
        let firstSymbolId;

        for (const layer of layers) {
            if (layer.type === 'symbol') {
                firstSymbolId = layer.id;
                break;
            }
        }
        return firstSymbolId;
      },

      getPopupContent(e){
        const props = e.features[0].properties
        let html = '';
        const species = (this.biopid_list.data.find(
                        item => item.biopid === this.selected_biopid
                        ).description);
        
        if('feat_desc' in props){
          html = '<p>Biopid ' +  props.biopid + 
                 '</p><p><span class="font-bold">'
                 + props.feat_desc + '<span></p>';
        }else if('patch_id' in props){
          html = '<hr class="h-px mt-5  bg-black">' +
                  '<p class="w-full text-center font-bold">' + species + '<p>' + 
                  '<p class="pt-.5">Road Region ID:  <span class="font-bold">' + 
                  props.road_region_id + '</span></p>' + 
                  '<p>Patch ID: <span class="font-bold">' + 
                  props.patch_id + '</span></p>'  + 
                  '<p>Year:  <span class="font-bold">' +  
                  props.year + '</span></p>' +
                 '<p>Acres:  <span class="font-bold">' + 
                  ( Math.round(props.area * 10) / 10 ).toLocaleString() + 
                  '</span></p>'  + 
                 '<p>Perimeter (ft.):  <span class="font-bold">' + 
                  Math.round(props.perimeter).toLocaleString() + 
                  '</span></p>';
        }
        return html;
      },

      highlightPatch(e){
        const layer_id = e.features[0].layer.id;
        const patch_id = e.features[0].properties.patch_id;

        this.map.setPaintProperty(layer_id, 'fill-color', 
                      [
                        'match', ['get', 'patch_id'],
                        patch_id,
                        'rgba(255, 0, 0, .9)',
                        this.paint[layer_id]['fill-color']
                      ]);
        
        this.map.setPaintProperty(layer_id, 'fill-outline-color', 
        [
          'match', ['get', 'patch_id'],
          patch_id,
          'rgba(0,255,255, .9)',
          this.paint[layer_id]['fill-outline-color']
        ]);
        this.focusedPatch = [layer_id, patch_id];
      },

      removePatchHighlight(){
        this.map.setPaintProperty(this.focusedPatch[0], 'fill-color', 
                          this.paint[this.focusedPatch[0]]['fill-color']);

        this.map.setPaintProperty(this.focusedPatch[0], 'fill-outline-color', 
        this.paint[this.focusedPatch[0]]['fill-outline-color']);
        this.focusedPatch = null;
      },

      restoreLayers(){
        this.removeLayers();
        this.addSources();
        if(this.selected_biopid){
          this.updatePatchTileSources();
          this.updateRangeLayers();
          this.updatePatchLayers();
        }
        this.repaintMap();
      },

      removeLayers(){
        for(const value of Object.values(this.layers)){
          if(this.map.getLayer(value.id)){
            this.map.removeLayer(value.id);
          }
        }
      },

      repaintMap(){
        const paint = this.selected_biopid?'outline_only':'fill';
        const layer_1 = this.layers.range.id;
        const layer_2 = this.layers.range_lines.id;
        
        if(this.map.getLayer(layer_1)){
          this.map.setFilter(layer_1,  
                  ['==', ['number',['get', 'biopid']], this.selected_biopid]);
          this.map.setPaintProperty(layer_1, 'fill-color', 
                          this.paint[paint]['fill-color']);
          this.map.setPaintProperty(layer_1,'fill-outline-color', 
                            this.paint[paint]['fill-outline-color']);
          // this.map.setPaintProperty(layer_1, 'fill-opacity-transition', 
          // this.paint.fill['fill-opacity-transition']);
        }
        if(this.map.getLayer(layer_2)){
          this.map.setFilter(layer_2,  
                  ['==', ['number',['get', 'biopid']], this.selected_biopid]);
          this.map.setPaintProperty(layer_2, 'line-color', 
                          this.paint[paint]['line-color']);
          this.map.setPaintProperty(layer_2,'line-width', 
                            this.paint[paint]['line-width']);
        }

        if(!this.selected_versions){ return; }

        if(this.selected_versions.includes(true)){

          const versions = ['86','95','02','07','12','15','20'];
          let ct = 0;

          while(ct < 7){
            const name = 'patches-' + versions[ct];

            if(this.map.getLayer(name)){
              this.map.setPaintProperty(name, 'fill-color', 
                                  this.paint[name]['fill-color']);
              this.map.setPaintProperty(name,'fill-outline-color', 
                                  this.paint[name]['fill-outline-color']);
              // this.map.setPaintProperty(name, 'fill-opacity-transition', 
              //                     this.paint[name]['fill-opacity-transition']);
            }
            ct++;
          }
        }
      },

      updatePatchLayers(){
        const versions = ['86','95','02','07','12','15','20']
        let ct = 0;
        // let label_layer = this.getLabelLayer();

        while(ct < versions.length){
          const name = 'patches-' + versions[ct];
          const id = 'patches_' + versions[ct];

          if(this.map.getLayer(name)){
            if(this.selected_versions[ct]){
              ct++;
              continue;
            }else{
              this.map.removeLayer(name);
            }        
          }else{
            if(this.selected_versions[ct]){

              this.map.addLayer(
                { 'id': this.layers[id].id,
                  'type': this.layers[id].type,
                  'source': this.sources[id].name, 
                  'source-layer': this.layers[id]['source-layer']
                  }
              ), this.getLabelLayer();
              // this.map.addLayer(this.layers[id]);
              this.addInteractions(this.layers[id].id);
              this.repaintMap();
            }
          }

          ct++;
        }
      },

      updateRangeLayers(){
        let  layer_added = false;

        if(!this.map.getLayer(this.layers.range.id)){
          this.map.addLayer(
            { 'id': this.layers.range.id,
              'type': this.layers.range.type,
              'source': this.sources.range.name, 
              'source-layer': this.layers.range['source-layer']
              }
          ), this.getLabelLayer();
          layer_added = true;
        }
        if(!this.map.getLayer(this.layers.range_lines.id)){
          this.map.addLayer(
            { 'id': this.layers.range_lines.id,
              'type': this.layers.range_lines.type,
              'source': this.sources.range_lines.name, 
              'source-layer': this.layers.range_lines['source-layer']
              }
          ), this.layers.range.id;
          layer_added = true;
        }
        if(layer_added){
          this.addInteractions(this.layers.range.id);
        }
      },

      updatePatchTileSources(){
        const yrs = ['1986', '1995', '2002', '2007', '2012', '2015', '2020'];
        const versions = ['86','95','02','07','12','15','20'];
        let ct = 0;

        while(ct < yrs.length){
          const name = "patches-" + versions[ct];
            this.map.getSource(name).setTiles([this.tile_root + 
            'patches/' + this.selected_biopid + '/' + yrs[ct] +
            '/{z}/{x}/{y}.pbf']);
          ct++;
        }
      },
 
      updatesSelected_versions(selected_versions){
        this.selected_versions = selected_versions;
      },

      zoomToFeature() {
        this.map.fitBounds(this.bounds);
      }
    }
  }

</script>
<style>
  .maplibregl-popup-close-button{
    margin-right: .3em;
    margin-top: .3em;
    padding-left: .2em;
    padding-right: .2em;
    padding-bottom: .2em;
    border-color: rgba(128, 128, 128, 0.336);
    border-width: thin;
    border-style: outset;
    font-weight: bold;
    font-size: larger;
    color:grey;
    border-radius: 0.15em;
  }
  .maplibregl-popup-content {
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 1em
  }

</style>
