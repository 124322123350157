<template>
  <div v-show="show"
       class="relative top-0 border-2 border-neutral-50 hover:shadow-xl
              h-screen">
    <div id="map" class="w-full h-full z-40" v-show="show"></div>
  </div>
  <div class="absolute top-1 left-1 w-1/6  z-10 bg-white bg-opacity-80 
              text-xs md:text-sm lg:text-sm xl:text-base 2xl:text-xl hidden" 
       id="legend">
    <div class="w-full grid grid-cols-2">
      <div class="text-left m-1.5 pr-3 align-bottom h-5" 
           v-if="selected_biopid">RANGE</div>
      <div class="text-left ml-3 mr-1.5 mt-3.5 h-1.5 bg-red-600" 
           v-if="selected_biopid">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
      <div class="text-left mx-1.5 mt-1.5 mb-0.5 pr-3 align-bottom h-5 
                  col-span-2" 
           v-if="patch_versions.includes(true)">PATCHES</div>
      <div class="text-right m-1.5 align-bottom h-5" 
           v-if="patch_versions[6]">2020</div>
      <div class="text-left m-1.5 h-5 bg-patches_20" 
           v-if="patch_versions[6]">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
      <div class="text-right m-1.5 align-bottom h-5" 
           v-if="patch_versions[5]">2015</div>
      <div class="text-left m-1.5 h-5 bg-patches_15" 
           v-if="patch_versions[5]">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
      <div class="text-right m-1.5 align-bottom h-5" 
           v-if="patch_versions[4]">2012</div>
      <div class="text-left m-1.5 h-5 bg-patches_12" 
           v-if="patch_versions[4]">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
      <div class="text-right m-1.5 align-bottom h-5" 
           v-if="patch_versions[3]">2007</div>
      <div class="text-left m-1.5 h-5 bg-patches_07" 
           v-if="patch_versions[3]">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
      <div class="text-right m-1.5 align-bottom h-5" 
           v-if="patch_versions[2]">2002</div>
      <div class="text-left m-1.5 h-5 bg-patches_02" 
           v-if="patch_versions[2]">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
      <div class="text-right m-1.5 align-bottom h-5" 
           v-if="patch_versions[1]">1995</div>
      <div class="text-left m-1.5 h-5 bg-patches_95" 
           v-if="patch_versions[1]">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
      <div class="text-right m-1.5 align-bottom h-5" 
           v-if="patch_versions[0]">1986</div>
      <div class="text-left m-1.5 h-5 bg-patches_86" 
           v-if="patch_versions[0]">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
      <div class="text-left mx-1.5 mt-1.5 mb-0.5 pr-3 align-bottom h-5 
                  col-span-2" 
           v-if="land_use">LAND</div>
      <div class="text-right m-1.5 align-bottom h-5" 
           v-if="land_use">Urban</div>
      <div class="text-left m-1.5 h-5 bg-[#ea686c]" 
           v-if="land_use">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
      <div class="text-right m-1.5 align-bottom h-5" 
           v-if="land_use">Water</div>
      <div class="text-left m-1.5 h-5 bg-[#1100bb]" 
           v-if="land_use">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
      <div class="text-right m-1.5 align-bottom h-5" 
           v-if="land_use">Ag.</div>
      <div class="text-left m-1.5 h-5 bg-[#d0a58c]" 
           v-if="land_use">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
      <div class="text-right m-1.5 align-bottom h-5" 
           v-if="land_use">Barren</div>
      <div class="text-left m-1.5 h-5 bg-[#cbcac9]" 
           v-if="land_use">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
      <div class="text-right m-1.5 align-bottom h-5" 
           v-if="land_use">Forest</div>
      <div class="text-left m-1.5 h-5 bg-[#afd893]" 
           v-if="land_use">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
      <div class="text-right m-1.5 align-bottom h-5" 
           v-if="land_use">Wetland</div>
      <div class="text-left m-1.5 h-5 bg-[#a8cde9]" 
           v-if="land_use">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
    </div>
  </div>
</template>

<script>

    import BasemapControl from 'maplibre-basemaps';
    import { ImageryControl } from '../assets/js/imageryControl.js';
    import 'maplibre-gl/dist/maplibre-gl.css';
    import maplibregl from 'maplibre-gl';
    import OpacityControl from './OpacityControl.vue';

    export default {
                
      name: 'MapComponent',
      
      props: [
        'land_use',
        'parent_height',
        'resize_map',
        'selected_biopid',
        'selected_overlay',
        'selected_versions',
        'server_error',
        'visible'
      ],

      data(){
          return{
            baseLayers: null,
            basemaps:{
              osm: {
                name: "Open Street Map",
                tiles: ['https://tile.openstreetmap.org/{z}/{x}/{y}.png'],
                maxzoom: 18,
                attribution: '<a href="https://www.openstreetmap.org/"  target="_blank">Open Street Map</a>'
              },

              satellite: {
                name: 'Satellite',
                tiles: ['https://api.maptiler.com/tiles/satellite-v2/{z}/{x}/{y}.jpg?key=qzs6NSideddtGPx79MOB'],
                maxzoom: 22,
                attribution: '<a href="https://www.maptiler.com/" target="_blank">Maptiler</a>'
              },

              hybrid: {
                name: "Hybrid",
                tiles: ['https://api.maptiler.com/maps/hybrid/?key=qzs6NSideddtGPx79MOB'],
                maxzoom: 22,
                attribution: '<a href="https://www.maptiler.com/" target="_blank">Maptiler</a>',
              },
            },

            default_style: {version: 8,sources: {},layers: []},// 'maplibre://styles/maplibre/outdoors-v12', //{version: 8,sources: {},layers: []},
            default_view: {
                'lat': 40.0790,
                'lng': -75.8510,
                'lat_small_screen': 40.1,
                'lng_small_screen': -74.56625054468779,
                'zoom': 7,
                'bearing': 0,
                'pitch': 10},
            feature_opacity: .8,
            land_use_opacity: .8,
            full_screen: false,
            map: null,
            ImageryControl,
            OpacityControl,
            patch_versions: [false,false,false,false,false,false,false],
            popup: null,
            show: true,
            small_screen: true
          }
      },
        
      emits: [
        'basemap_change',
        'feature_opacity',
        'map',
        'map_loaded',
        'popup',
        'state',
      ],

      watch:{
        feature_opacity: function(){
          this.$emit('feature_opacity', this.feature_opacity);
        },

        full_screen: {
          immediate: false,
          handler(newVal) {
            this.setControlVisibility(newVal);
            this.$emit('state', this.full_screen?2:1);
          }
        },

        selected_biopid: function(){
          if(!this.selected_biopid){
            this.setDefaultMapView();
          }
        },

        selected_versions: {
          deep: true,
          handler(){
            this.patch_versions = this.selected_versions;
            this.updateOpacityControlVisibility();
            return;
          }
        },
        
        server_error: function(){
            if(this.server_error){
              this.setDefaultMapView();
            }
        },

        visible: {
          deep: true,
          handler(){
            if(this.visible[this.$.type.name]){
              this.show = true;
            }else{
              this.show = false;
            }
            this.map.resize();
          }
        }
      },

      mounted(){
        this.createMap();
        this.addResizer();

        this.baseLayers = { 
          'osm': this.basemaps.osm,
          'terrain': this.basemaps.terrain,
          'hybrid': this.basemaps.hybrid,
        },
        this.createPopup();
      },

      methods: {

        addControls(){
          const control_list= [
              [new BasemapControl({ basemaps: this.basemaps,  height: '85px' 
                 }), 'top-right', 'xs', 'basemaps'],
              [new ImageryControl(), 'top-right', 'xs', 'imagery'],
              [new maplibregl.NavigationControl(),
                                        'top-right', 'md', 'navigation'],
              [new maplibregl.AttributionControl({
                  customAttribution: 
                    `<span 
                    title="Site Designed and Created by Mark Corbalis">
            &nbsp;&nbsp;&nbsp;
            <a href="https://www.linkedin.com/in/mark-corbalis-325b87292"
                target="_blank">Mark Corbalis</a>&nbsp;</span>`
                }),'bottom-right', 'xs', 'attribution'],
              [new maplibregl.FullscreenControl(
                      {container: document.querySelector('body')}),
                                        'top-right', 'md', 'fullscreen'],
              
              [new OpacityControl('Patches', '1'), 'bottom-right', 'xs', 'opacity'],
              [new OpacityControl('Land Use', '2'), 'bottom-right', 'xs', 'opacity'],
              [new maplibregl.ScaleControl({unit: 'imperial'}),
                                          'bottom-right', 'md', 'scale'],
          ];
          for (const control of control_list) {
            this.map.addControl(control[0], control[1]);
          }
          this.addOpacityControlHandlers();
          let el = document
                     .getElementsByClassName('maplibregl-ctrl-top-right')[0];
          el.classList.add('mt-10');
          el = document.getElementsByClassName('maplibregl-ctrl-group')[0]
               .getElementsByTagName('button')[0];
          el.innerHTML = '';
          this.setControlVisibility(true);
        },

        addOpacityControlHandlers(){
            const ctl1 = document.getElementById('opacity-ctl-1');
            const ctl2 = document.getElementById('opacity-ctl-2');

            if(ctl1 && ctl2){

              ctl1.value = this.feature_opacity * 100;
              ctl2.value = this.feature_opacity * 100;

              ctl1.addEventListener('change', (e) => {
                this.adjustFeatureOpacity(e);
              });
              ctl2.addEventListener('change', (e) => {
                this.adjustLandUseOpacity(e);
              });
            }
        },

        addTerrain(){
          this.map.addSource('terrainSource', {
              'type': 'raster-dem',
              'url': `https://api.maptiler.com/tiles/terrain-rgb-v2/tiles.json?key=qzs6NSideddtGPx79MOB`,
              'tileSize': 512,
              'maxzoom': 14
            });

            this.map.setTerrain({ 'source': 'terrainSource', 'exaggeration': 1.5 });

            this.map.addControl(
              new maplibregl.TerrainControl({
              source: 'terrainSource',
              exaggeration: 5
              })
            );
        },

        adjustFeatureOpacity(e){
          this.feature_opacity = parseInt(e.target.value, 10) / 100;
        },

        adjustLandUseOpacity(e){
          this.land_use_opacity = parseInt(e.target.value, 10) / 100;
          if(this.map.getLayer('lulc')){
            this.map.setPaintProperty('lulc', 'raster-opacity', 
                                        this.land_use_opacity);
          }
        },

        addResizer(){
          const observer = new ResizeObserver(() => {
            this.map.resize();
          });
          const el = document.getElementById('map');
        
          observer.observe(el);
        },

        createMap(){
          this.map = new maplibregl.Map({
              container:  'map',
              style: this.default_style,
              antialias: true,
              attributionControl: false,
              ScaleControl: false,
              preserveDrawingBuffer: true,
              minZoom: 6,
              maxZoom: 18
          });

          this.setDefaultMapView();
          this.$emit('map', this.map);

          this.map.on('load', () => {
            this.$emit('map_loaded', true);
            this.insertLegend();
            this.addControls();

            this.addTerrain();
          });  
        },

        createPopup(){
          this.popup = new maplibregl.Popup({
            closeButton: true,
            closeOnClick: true,
            closeOnMove: true
          });

          this.$emit('popup', this.popup);
        },

        handleBasemapChange(){
          setTimeout(()=>{this.$emit('basemap_change', true)}, 1000);
        },

        insertLegend(){
          const el = document.querySelector('#legend');
          const map = document.querySelector('#map');
          map.prepend(el);
          el.classList.remove('hidden');
        },

        setControlVisibility(show){
          const visible = show?show:false;
          const ctl = document
                        .getElementsByClassName('maplibregl-control-container')[0];
            if(!ctl){return;}
            if(visible){
              ctl.classList.remove('hidden')
            }else{
              ctl.classList.add('hidden')
            }
        },

        setFeatureOpacity(opacity){

          this.features.setProps(
              {'opacity': opacity}
          );
          
          this.map.setPaintProperty('feature-raster','raster-opacity', 
          opacity);
        },

        setMapView(view){
          
            this.map.setZoom(view.zoom);
            this.map.setBearing(view.bearing);
            this.map.setPitch(view.pitch);
            if(this.small_screen){
              this.map.setCenter([view.lng_small_screen, 
                                                  view.lat_small_screen]);
            }else{
              this.map.setCenter([view.lng, view.lat]);
            }
        },

        setDefaultMapView(){
          this.setMapView(this.default_view);
        },

        updateOpacityControlVisibility(){
          const el = document.getElementById('opacity-control-container-1');
          
          if(!el){ return; }
          
          if(this.patch_versions.includes(true)){
            el.classList.remove('hidden');
          }else{
            el.classList.add('hidden');
          }
        }
      }
    }

</script>

<style>
  .maplibre-improve-map {
    display: none;
  }

  .maplibre-ctrl-basemap button
  { 
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTQuODQ5cHgiIGhlaWdodD0iNTQuODQ5cHgiIHZpZXdCb3g9IjAgMCA1NC44NDkgNTQuODQ5IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1NC44NDkgNTQuODQ5OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PGc+PGc+PHBhdGggZD0iTTU0LjQ5NywzOS42MTRsLTEwLjM2My00LjQ5bC0xNC45MTcsNS45NjhjLTAuNTM3LDAuMjE0LTEuMTY1LDAuMzE5LTEuNzkzLDAuMzE5Yy0wLjYyNywwLTEuMjU0LTAuMTA0LTEuNzktMC4zMThsLTE0LjkyMS01Ljk2OEwwLjM1MSwzOS42MTRjLTAuNDcyLDAuMjAzLTAuNDY3LDAuNTI0LDAuMDEsMC43MTZMMjYuNTYsNTAuODFjMC40NzcsMC4xOTEsMS4yNTEsMC4xOTEsMS43MjksMEw1NC40ODgsNDAuMzNDNTQuOTY0LDQwLjEzOSw1NC45NjksMzkuODE3LDU0LjQ5NywzOS42MTR6Ii8+PHBhdGggZD0iTTU0LjQ5NywyNy41MTJsLTEwLjM2NC00LjQ5MWwtMTQuOTE2LDUuOTY2Yy0wLjUzNiwwLjIxNS0xLjE2NSwwLjMyMS0xLjc5MiwwLjMyMWMtMC42MjgsMC0xLjI1Ni0wLjEwNi0xLjc5My0wLjMyMWwtMTQuOTE4LTUuOTY2TDAuMzUxLDI3LjUxMmMtMC40NzIsMC4yMDMtMC40NjcsMC41MjMsMC4wMSwwLjcxNkwyNi41NiwzOC43MDZjMC40NzcsMC4xOSwxLjI1MSwwLjE5LDEuNzI5LDBsMjYuMTk5LTEwLjQ3OUM1NC45NjQsMjguMDM2LDU0Ljk2OSwyNy43MTYsNTQuNDk3LDI3LjUxMnoiLz48cGF0aCBkPSJNMC4zNjEsMTYuMTI1bDEzLjY2Miw1LjQ2NWwxMi41MzcsNS4wMTVjMC40NzcsMC4xOTEsMS4yNTEsMC4xOTEsMS43MjksMGwxMi41NDEtNS4wMTZsMTMuNjU4LTUuNDYzYzAuNDc3LTAuMTkxLDAuNDgtMC41MTEsMC4wMS0wLjcxNkwyOC4yNzcsNC4wNDhjLTAuNDcxLTAuMjA0LTEuMjM2LTAuMjA0LTEuNzA4LDBMMC4zNTEsMTUuNDFDLTAuMTIxLDE1LjYxNC0wLjExNiwxNS45MzUsMC4zNjEsMTYuMTI1eiIvPjwvZz48L2c+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjwvc3ZnPg==) !important;       
      background-position: center;
      background-repeat: no-repeat;
      background-size: 70%;
  }
  .marks-image-control {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjRweCIgdmlld0JveD0iMCAtOTYwIDk2MCA5NjAiIHdpZHRoPSIyNHB4IiBmaWxsPSIjMDAwMDAwIj48cGF0aCBkPSJNMzYwLTQwMGg0MDBMNjIyLTU4MGwtOTIgMTIwLTYyLTgwLTEwOCAxNDBabS00MCAxNjBxLTMzIDAtNTYuNS0yMy41VDI0MC0zMjB2LTQ4MHEwLTMzIDIzLjUtNTYuNVQzMjAtODgwaDQ4MHEzMyAwIDU2LjUgMjMuNVQ4ODAtODAwdjQ4MHEwIDMzLTIzLjUgNTYuNVQ4MDAtMjQwSDMyMFptMC04MGg0ODB2LTQ4MEgzMjB2NDgwWk0xNjAtODBxLTMzIDAtNTYuNS0yMy41VDgwLTE2MHYtNTYwaDgwdjU2MGg1NjB2ODBIMTYwWm0xNjAtNzIwdjQ4MC00ODBaIi8+PC9zdmc+) !important; ;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 70%;
}
</style>