import { createRouter, createWebHashHistory } from 'vue-router'
import HabitatComponent from '../components/HabitatComponent.vue'
import HomeComponent from '../components/HomeComponent.vue'

const routes = [
  { path: '/', name: 'Home', component: HomeComponent, alias: '/home' },
  { path: '/Home', name: 'Home', component: HomeComponent, alias: '/home' },
  { path: '/Habitat', name: 'Habitat', component: HabitatComponent, alias: '/habitat' },
  { path: '/:pathMatch(.*)*',  component: HomeComponent, alias: '/home' },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
