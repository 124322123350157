<template>
 
 <div class="text-xs md:text-sm lg:text-base xl:text-lg 2xl:text-md 
             align-top items-center mt-3  bg-white">
  <div class="w-full h-full pt-2bg-[#8f3c46]/20">
  <div class="flex ml-5 mb-2 flex-wrap">
    <div class="pb-1 mr-6">
      <label for="biopid" 
              class="pl-2 font-bold text-hcap_green
                     text-xs md:text-sm lg:text-sm xl:text-base 2xl:text-xl"
      >Habitat Patches</label>
    </div>
  </div>
  <form id="form-version">
    <div class="flex flex-wrap  text-xs md:text-sm lg:text-sm xl:text-base 
                2xl:text-xl mx-1.5 pl-7 rounded">
      <div data-id="v2020" 
            class="basis-2/6 mb-2">
        <input type="checkbox" id="v20" :disabled="!selected_biopid" 
                value="2020" data-id="v20" 
                v-model="selected_versions[6]">
          <label for="v20"  data-id="v20" 
                class="pl-2 text-slate-800 text"
        >2020</label>
      </div>
      <div data-id="v15" 
            class="basis-2/6 mb-2">
        <input type="checkbox" id="v15" :disabled="!selected_biopid" 
                value="2015" data-id="v15" 
                v-model="selected_versions[5]">
        <label for="v15"  data-id="v15" 
                class="pl-2 text-slate-800"
        >2015</label>
      </div>
      <div data-id="v12" 
            class="basis-2/6 mb-2">
        <input type="checkbox" id="v12" :disabled="!selected_biopid" 
                value="2012" data-id="v12" 
                v-model="selected_versions[4]">
        <label for="v12"  data-id="v12" 
                class="pl-2 text-slate-800"
        >2012</label>
      </div>
      <div data-id="v07" 
            class="basis-2/6 mb-2">
        <input type="checkbox" id="v07" :disabled="!selected_biopid" 
                value="2007" data-id="v07" 
                v-model="selected_versions[3]">
        <label for="v07"  data-id="v07" 
                class="pl-2 text-slate-800"
        >2007</label>
      </div>
      <div data-id="v02" 
            class="basis-2/6 mb-2">
        <input type="checkbox" id="v02" :disabled="!selected_biopid" 
                value="2002" data-id="v02" 
                v-model="selected_versions[2]">
        <label for="v02"  data-id="v02" 
                class="pl-2 text-slate-800"
        >2002</label>
      </div>
      <div data-id="v95" 
            class="basis-2/6 mb-2">
        <input type="checkbox" id="v95" :disabled="!selected_biopid"
                value="1995" data-id="v95" 
                v-model="selected_versions[1]">
        <label for="v95"  data-id="v95" 
                class="pl-2 text-slate-800"
        >1995</label>
      </div>
      <div data-id="v86" 
            class="basis-2/6 mb-2">
        <input type="checkbox" id="v86" :disabled="!selected_biopid"
                value="1986" data-id="v86" 
                v-model="selected_versions[0]">
        <label for="v86"  data-id="v86" 
                class="pl-2 text-slate-800"
        >1986</label>
      </div>
    </div>
  </form>
  </div>
</div>

</template>

<script>

  export default {

    name: "VersionMenu",

    props:[
      'selected_biopid'
    ],

    emits:[
      'selected_versions'
    ],

    data(){
      return{
          selected_versions: [false,false,false,false,false,false,false],
        }
    },

    watch:{

      selected_biopid: function(){
        if(!this.selected_biopid){
          this.resetForm();
        }
      },

      selected_versions:{
        deep: true,
        handler(){
          this.$emit('selected_versions', this.selected_versions);
        }
      },
      
    },

    methods:{
      resetForm(){
        document.getElementById("form-version").reset();
      }
    }
  }

</script>
