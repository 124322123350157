<template>
  <div class="w-screen h-screen snap-proximity snap-x"> 
    <div class="fabsolute inset-0 bg-flowers bg-cover">
      <div class="basis-full h-screen text-white text-9xl pl-5 pt-5">
    Habitat Change
        <div class="w-1/2 float-right text-white bg-[#8f3c46]/80
                rounded-xl text-2xl mt-14 mr-14 p-5">Lorem ipsum dolor sit amet,
                consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                labore et dolore magna aliqua. Ut enim ad minim veniam, quis 
                nostrud exercitation ullamco laboris nisi ut aliquip ex ea 
                commodo consequat. Duis aute irure dolor in reprehenderit in 
                voluptate velit esse cillum dolore eu fugiat nulla pariatur. 
                Excepteur sint occaecat cupidatat non proident, sunt in culpa 
                qui officia deserunt mollit anim id est laborum.
                <p class="w-full text-center"><router-link class="hover:underline font-bold" to="/habitat">-> Maps -> </router-link></p></div>
      </div>
  
      
    </div>

    <div class=" basis-full h-screen snap-center bg-marsh bg-cover">

      <div class="w-1/2 float-left text-white bg-amber-600/80
                rounded-xl text-2xl mt-10 ml-14 p-5">Sed ut perspiciatis unde 
                omnis iste natus error sit voluptatem accusantium doloremque 
                laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore 
                veritatis et quasi architecto beatae vitae dicta sunt explicabo.
              </div>
      </div>

    <div class="bg-[#446b00] sticky bottom-0 h-fit text-white">
      <div class="container grid grid-cols-12 ">

          <div class="col-span-3 ml-6">
            <div>
              <div class="textwidget">
                <p>
                <a class="mb-2 mr-5 d-block float-left" style="width: 100%;" href="//nj.gov/opra/">
                  <img decoding="async" class="opra" :src="opra_png" alt="OPRA Logo"></a><br>
                <a class="mb-4 d-block float-left" href="https://my.state.nj.us/openam/UI/Login">
                  <img decoding="async" class="mynj" :src="myNJ_png" 
                       alt="myNJ Portal Logo"></a></p>
              </div>
            </div>      
          </div>
         <div class="textwidget custom-html-widget col-span-3 col-start-10 text-left">
          <p class="mb-0">
          <a href="https://dep.nj.gov/twitter/" class="toggle-target" rel="noopener"><i class="fab fa-square-x-twitter fa-2x nj-gray" aria-hidden="true" title="Twitter" alt="Twitter"></i><span class="sr-only">Twitter</span></a> 
          <a href="https://dep.nj.gov/facebook/" class="toggle-target" rel="noopener"><i class="fab fa-facebook-square fa-2x nj-gray" aria-hidden="true" title="Facebook" alt="Facebook"></i><span class="sr-only">Facebook</span></a>
          <a href="https://dep.nj.gov/instagram/" class="toggle-target" rel="noopener"><i class="fab fa-instagram fa-2x nj-gray" aria-hidden="true" title="Instagram" alt="Instagram"></i><span class="sr-only">Instagram</span></a>
          <a href="https://dep.nj.gov/youtube/" class="toggle-target" rel="noopener"><i class="fab fa-youtube-square fa-2x nj-gray" aria-hidden="true" title="YouTube" alt="YouTube"></i><span class="sr-only">YouTube</span></a>
        </p>
        <address>Copyright © State of New Jersey, 1996-2024<br>
            Department of Environmental Protection<br>
            P. O. Box 420<br>
            Trenton, NJ 08625<br>
            609-777-3373</address>
          </div>
      </div>
    </div>
    </div>


</template>

<script>
    import opra_png from '../assets/img/opra.png';
    import myNJ_png from '../assets/img/myNJ.png';

  export default {

    name: "HomeComponent",

    data(){
      return{
        myNJ_png,
        opra_png
      }
    }
  }

</script>