<template>
  
  <div class="flex bg-white w-full pb-2 border-b-2 border-hcap_green/20"
        id="header">
    <div class="h-10 w-full pl-3">
      <div class="flex flex-nowrap items-center">
        <div class="bg-white flex flex-nowrap basis-12 md:basis-18 ">
          <img class="w-full h-full mx-auto"  :src="landscape_logo_svg">
        </div>
        <div class="flex text-left text-base md:text-lg lg:text-xl xl:text-2xl   
              h-10 items-end ml-2 font-bold font-roboto text-hcap_green">
          <span>Habitat Change</span>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

  import grass_svg from "../assets/img/grass.svg" 
  import landscape_logo_svg from "../assets/img/landscape_logo_green_bg.svg" 
  import landscape_svg from "../assets/img/landscape.svg" 

  export default {
    name: 'HomeComponent',

    data() {
      return {
        grass_svg,
        landscape_logo_svg,
        landscape_svg,
      }
    },

    props: {
      msg: String
    }
  }

</script>
