<template>
  <div v-show="show"
       class="border-2  h-full border-neutral-100 hover:shadow--xl
             bg-hcap_brown/10">
    <div class="grid grid-cols-2 w-full">
      <div class="align-items-center col-span-2 grid grid-cols-12
                  justify-content-between justify-start gap-2 bg-hcap_brown">
        
        <span class="col-span-4 pl-6 h-6 text-xs text-left pt-1" 
              title="Download CSV" 
              id="download-data-three-csv">
        </span>
        <span class="col-span-4 w-full text-center text-white"
        >Patch Count/Area</span>

        <!-- <div class="h-6 col-span-1 inline text-white pr-0.5"
            style="font-variation-settings: 'wght' 700, 'GRAD' 0, 'opsz' 48;"
            id="table-expander">
          <span class="font-symbol material-symbols-outlined w-6 float-right"
                v-show="!full_screen"   
                @click="full_screen = true">fullscreen</span> 
          <span class="font-symbol material-symbols-outlined w-6 float-right"
                v-show="full_screen"
                @click="full_screen = false">fullscreen_exit</span>
        </div> -->

      </div>
      <div v-show="show_timer && !server_error"
        class="col-span-12 mt-10 inline h-8 w-8 animate-spin rounded-full
               border-4 border-solid border-current border-e-transparent 
               border-hcap_blue align-middle mx-auto
               motion-reduce:animate-[spin_1.5s_linear_infinite]"
        role="status">
          <span class="!absolute !-m-px !h-px !w-px !overflow-hidden 
                 !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
          >Loading...</span >
      </div>
      <div class="h-full w-full flex items-center text-red-600"
          v-show="server_error">
        An error has occured.  Try refreshing the page.
      </div>

      <div class="card col-span-2 h-full" 
           v-show="selected_biopid && !show_timer">
        <DataTable :value="records" tableStyle="min-width: 50rem" 
                   resizableColumns columnResizeMode="expand"
                   :size="'small'" 
        stripedRows showGridlines selectionMode="single">

          <Column v-for="col of columns" 
                  :key="col.field" 
                  :field="col.field" 
                  :style="col.style"
                  :header="col.header">
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>

  import Column from 'primevue/column';
  import DataTable from 'primevue/datatable';
  import { json2csv } from 'json-2-csv';

  export default {

    name: 'DataThreeComponent',

    components: {
      Column,
      DataTable
    },
    
    props: [
      'biopid_data',
      'selected_overlay',
      'selected_biopid',
      'server_error',
      'show_timer',
      'species_name',
      'visible'
    ],
    
    emits: [
      'state',
    ],

    data() {
      return {
        columns: [
          { field: 'year', header: 'Year'},
          { field: 'patch_count', header: 'Patches'},
          { field: 'count_change', header: 'Change'},
          { field: 'total_area', header: 'Acres'},
          { field: 'total_area_change', header: 'Change'}
        ],
        csvLink: null,
        dataset: 'patches',
        full_screen: false,
        header: '',
        height: 0,
        records: null,
        show: true,
        show_link: false,
        show_menu: false,
        title: ''
      }
    },

    watch:{

      biopid_data: function(){
        if(!this.biopid_data){
          return;
        }
        this.prepareData();
        this.createCsvLink();

      },

      dataset: function(){
        this.updateColumnHeader();
        this.prepareData();
        this.createCsvLink();
      },

      full_screen: function(){
        this.$emit('state', this.full_screen?2:1);
      },

      selected_biopid: function(){
        if(!this.selected_biopid){
          this.reset();
        }
      },

      visible: {
        deep: true,
        handler(){
          if(this.visible[this.$.type.name]){
            this.show = true;
          }else{
            this.show = false;
          }
        }
      },
    },

    methods:{
      createCsvLink(){
        const csvContent = json2csv(this.biopid_data[this.dataset]);

        const blob = new Blob([csvContent], 
                                            { type: 'text/csv;charset=utf-8,' });
        const url = URL.createObjectURL(blob);
        const linkText = document.createTextNode('Export CSV');

        let link = document.createElement('a');
        link.classList.add('w-32');
        link.classList.add('text-white');
        link.classList.add('text-xs');
        link.classList.add('hover:underline');
        link.classList.add('hover:font-bold');
        link.setAttribute('href', url);
        link.setAttribute('id', 'csv-link');
        link.setAttribute('download', 
                          this.species_name  + '(' + this.header  +').csv');
        
        link.appendChild(linkText);
        this.csvLink = link;

        const el = document.getElementById('download-data-three-csv');
        el.replaceChildren();
        el.appendChild(this.csvLink);

      },

      prepareData(){

            if(!this.selected_biopid){
              return [];
            }
            if(!this.biopid_data){
              return [];
            }
            
            const keys = ['year','patch_count','count_change', 'total_area',
                          'total_area_change']
            let rows = [];
            let data = {};
            let col_value = '';


            for (const entry of this.biopid_data[this.dataset]) {
              data = {};

              for (const [key, value] of  Object.entries(entry)){
                if(!keys.includes(key)){
                  continue;
                }
                if(key == this.dataset){
                  if( value != col_value){
                    data[key] = value;
                    col_value = value;
                  }else{
                     data[key] = value;
                  }
                }else{
                  if(key == 'total_area' || key== 'total_area_change'
                     || key== 'patch_count' || key== 'count_change'
                  ){
                    data[key] = (Math.round(value * 10) / 10).toLocaleString();
                  }else{
                    data[key] = value;
                  }
                }
              }

              rows.push(data);
            }

            this.records =  rows;
            this.show_menu = true;
      },

      redrawTable(e){
        this.dataset = e.target.dataset.id;
      },

      reset(){
        this.csvLink = null;
        this.records = null,
        this.show_link = false;
        this.show_menu = false;
        this.title = '';
      },

      updateColumnHeader(){
        let header = '';

        switch(this.dataset){
          case 'succession_type':
           header = 'Type';
            break;
          case 'class':
            header = 'Class';
            break;
          case 'category':
           header = 'Category';
            break;
          case 'riparian':
           header = 'Riparian';
            break;
          default:
            header = 'Type';
        }
        this.columns[0].field = this.dataset;
        this.columns[0].header = header;
        this.header = header;
      }
    }
  }
</script>

