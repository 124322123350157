<template>
  <div class="w-full  bg-white">
    <div class="w-full h-full pl-7 py-2 bg-neutral-50">
    <label for="lu_chkbx" class="pr-2 font-bold text-hcap_green
    text-xs md:text-sm lg:text-sm xl:text-base 2xl:text-xl">Land Use</label>
    <input id="lu_chkbx" type="checkbox" @click="toggleLayer">
  </div>
  </div>
</template>

<script>

  import axios from 'axios';
  import maplibregl from 'maplibre-gl';

  export default {

    name: 'LandUseComponent',

    props: [
      'opacity',
      'map'
    ],

    emits: [
      'land_use'
    ],

    watch:{
      opacity: function(){
        this.adjustLandUseOpacity();
      }
    },

    data(){
        return{
          click_info: null,

          layers: {

            feature:{
              'id': 'feature',
              'type': 'fill',
              'source': 'feature_source',
              'layout': {},
              'paint': {
                  'fill-color': '#F433FF',
                  'fill-opacity': 0.9,
                  'fill-outline-color': '#000000'
              }
            },
            
            lulc: {
              'id': 'lulc',
              'type': 'raster',
              'source': 'lulc_source',
              'minzoom': 5,
              'maxzoom': 17
            }
          },

          popup: null,

          sources: {

            feature: 
            { 'type': 'geojson',
              'data': {}
            },

            lulc:
            {'name': 'lulc_source',
              'type': 'raster',
              'tiles': ['https://d3r2ax3k1kd026.cloudfront.net/' + 
                        'lulc/{z}/{x}/{y}.png'],
              'tileSize': 256,
              'attribution':
              '&copy; <a href="https://njogis-newjersey.opendata.arcgis.com/'
              +'search?q=land%20use">NJ Dept. of Environmental Protection</a>'
            }   
          }
        }
    },

    methods: {
      addClickHandler() {
        this.map.on('click', (e) => {
          if(!this.map.getLayer('lulc')) { return; }
          this.openPopup(e.lngLat);
          this.getPointData(e);
        });
      },

      addFeatureLayer(){
        this.sources.feature.data = this.click_info.geom; 
        this.removeFeatureLayer();
        this.map.addSource('feature_source', this.sources.feature);
        this.map.addLayer(this.layers.feature);
        this.zoomToFeature();
      },

      addLandUseLayer() {
        if (this.map.getLayer(this.layers.lulc.id)) {
          return;
        }

        if (!this.map.getSource(this.sources.lulc.name)) {
          this.map.addSource(this.sources.lulc.name, this.sources.lulc);
        }

        const reference_layer = 
                          this.map.getLayer('range_lines')?'range_lines': null;

        this.map.addLayer(this.layers.lulc, reference_layer);
        this.addClickHandler();
        this.toggleOpacitycontrol(true);
        this.$emit('land_use', true);
      },

      getPointData(e) {

        let url = 'https://bannmdt6pkkb2sjsd24vfb6u6a0ytsyv.lambda-url.us-east'
            url += '-1.on.aws/?action=';
            url += `get_land_use&lat=${e.lngLat.lat}&lng=`;
            url += `${e.lngLat.lng}`;

        axios
          .get(url)
          .then(response => {
            if (Object.prototype.hasOwnProperty.call(
              response.data, 'message')) {
              console.log(response.data['message']);
            } else {
              this.click_info = response.data;
              this.updatePopupContent();
              this.addFeatureLayer();
            }
          })
          .catch((err) => {
            console.error(err);
          });
      },

      updatePopupContent() {
        const html = this.prepareData();
        this.popup.setHTML(html);
      },

      openPopup(lngLat){
        const options = {className: 'h-72 w-52 overflow-auto rounded',
                         closeOnClick: true
        };
        const html = `<svg class='w-full' xmlns="http://www.w3.org/2000/svg" 
        height="30px" 
        viewBox="0 0 200 200"><circle fill="#413F43" stroke="#413F43" 
        stroke-width="2" r="15" cx="40" cy="100"><animate 
        attributeName="opacity" calcMode="spline" dur="2" values="1;0;1;" 
        keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.4">
        </animate></circle><circle fill="#413F43" stroke="#413F43" 
        stroke-width="2" r="15" cx="100" cy="100"><animate 
        attributeName="opacity" calcMode="spline" dur="2" values="1;0;1;" 
        keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.2">
        </animate></circle><circle fill="#413F43" stroke="#413F43" 
        stroke-width="2" r="15" cx="160" cy="100"><animate 
        attributeName="opacity" calcMode="spline" dur="2" values="1;0;1;" 
        keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="0">
        </animate></circle></svg>`      
        if(this.popup){
          this.popup.remove();
        }
        this.popup = new maplibregl.Popup(options);

        this.popup.on('open', () => {this.map.dragPan.disable();});
        this.popup.on('close', () => {
          this.map.dragPan.enable();
          this.removeFeatureLayer();
          this.popup = null;
        });
        this.popup.setLngLat(lngLat)
        this.popup.setHTML(html)
        this.popup.addTo(this.map);
      },

      prepareData() {
        if (!this.click_info) {
          return;
        }

        const data = this.click_info['data'];
        const tbl = document.createElement('table');
        let tr;
        let td;
        const cls = ['border', 'border-solid','border-neutral-50'];

        tbl.classList.add('w-full');
        tbl.classList.add('mt-5');
        tbl.classList.add(...cls);

        for (const datum of data.reverse()) {
          for (let [key, value] of Object.entries(datum)) {
            if(key == 'succession_type'){ key = 'succession'; }
            if (key == 'year') {
              tr = tbl.insertRow();
              td = tr.insertCell();
              td.appendChild(document.createTextNode(value));
              td.setAttribute('colSpan', '2');
              td.classList.add('bg-hcap_logo_green/90');
              td.classList.add('text-white');
              td.classList.add('w-full');
              td.classList.add(...cls);
              td.classList.add('text-center');
            } else {
              tr = tbl.insertRow();
              td = tr.insertCell();
              td.appendChild(document.createTextNode(key + ': '));
              td.classList.add('text-right');
              td.classList.add('align-top');
              td.classList.add('text-neutral-600');
              td.classList.add('capitalize');
              td = tr.insertCell();
              td.appendChild(document.createTextNode(value));
              td.classList.add('text-left');
              td.classList.add('pl-2');
              td.classList.add('text-neutral-600');
            }
          }
        }
        return tbl.outerHTML;
      },

      removeFeatureLayer() {
        if (this.map.getLayer(this.layers.feature.id)) {
          this.map.removeLayer(this.layers.feature.id);
        }

        if (this.map.getSource('feature_source')) {
          this.map.removeSource('feature_source');
        }
      },

      removeLandUseLayer() {
        if (this.map.getLayer(this.layers.lulc.id)) {
          this.map.removeLayer(this.layers.lulc.id);
        }

        if (this.map.getSource(this.sources.lulc.name)) {
          this.map.removeSource(this.sources.lulc.name);
        }

        this.toggleOpacitycontrol(false);

        this.$emit('land_use', false);
      },

      toggleLayer(e) {
        const on = e.target.checked;

        if (on) {
          this.addLandUseLayer();
        } else {
          this.removeLandUseLayer();
        }
        this.$emit('land_use', on);
      },

      toggleOpacitycontrol(on){
          const el = document.getElementById('opacity-control-container-2');

          if(el){
            if(on){
              el.classList.remove('hidden');
            }else{
              el.classList.add('hidden');
            }
          }
      },
      
      zoomToFeature() {
        if(!Object.prototype.hasOwnProperty.call(this.sources.feature.data, 
           'coordinates')){ return; }

        const coordinates = this.sources.feature.data.coordinates[0][0];
        const bounds = coordinates.reduce((bounds, coord) => {
                    return bounds.extend(coord);
                }, new maplibregl.LngLatBounds(coordinates[0], coordinates[0]));

        this.map.fitBounds(bounds, {
            padding: 20
        });
      }
    }
  }
</script>
<style>
  .maplibregl-popup-content{
    color: black;
    font-weight: bold;
    background-color: white !important;

  }
</style>